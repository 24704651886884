import React from "react";
import {
    BrowserRouter as Router,
    Link
} from "react-router-dom";
import { Table, Form, Modal, Button } from "react-bootstrap";
import Noticias from "../Noticias";
import { cfecha, getQueryVariable, pcortado, api_adm, Tabla_adm } from "../subcomponentes";


class Noticias_adm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            noticias: props.noticias,
            show: false,
            show2: false,
            show3: false,
            id: 0,
            titulo: "",
            parrafo: "",
            imagen: "",
            checked: false,
            fecha: ""
        };
        this.handleChangetitulo = this.handleChangetitulo.bind(this);
        this.handleChangeparrafo = this.handleChangeparrafo.bind(this);
        this.handleChangeimagen = this.handleChangeimagen.bind(this);
    }
    handleChangetitulo(event) {
        this.setState({ titulo: event.target.value });
    }
    handleChangeparrafo(event) {
        this.setState({ parrafo: event.target.value });
    }
    handleChangeimagen(event) {

        for (const file of event.target.files) {
            if (file.type && file.type.indexOf('image') === -1) {
                console.log('File is not an image.', file.type, file);
                return;
            }

            const reader = new FileReader();
            reader.addEventListener('load', (event) => {
                this.setState({ imagen: event.target.result });
            });
            reader.readAsDataURL(file);
        }

    }

    render() {

        const query = getQueryVariable("noticia");
        const { noticias, parrafo, show, id, show2, titulo, imagen, show3, fecha } = this.state;
        const parrafoc = pcortado(parrafo);

        let checked = this.state.checked;

        if (checked == 1) {
            checked = true;
        } else if (checked == 0) {
            checked = false;
        }
        console.log(imagen);


        const filarender = noticias.map((noticia) => {
            return (
                <tr>
                    <td>{noticia.titulo}</td>
                    <td>{noticia.id}</td>
                    <td>{noticia.fecha}</td>
                    <td>
                        <img onClick={() => { this.setState({ show2: true, checked: noticia.inicio, id: noticia.id, parrafo: noticia.parrafo, titulo: noticia.titulo, fecha: noticia.fecha, imagen: noticia.imagen }); }} style={{ cursor: "pointer", width: "20px" }} src="https://www.pngkit.com/png/full/94-943587_este-grficos-es-editar-el-icono-sobre-editores.png" />
                    </td>
                    <td>
                        <img onClick={() => { this.setState({ show: true, id: noticia.id }); }} style={{ cursor: "pointer", width: "20px" }} src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5f/Red_X.svg/1024px-Red_X.svg.png" />
                    </td>
                </tr>
            );
        });


        return (
            <div className="fondo_noticias_adm" >
                <Modal show={show} onHide={() => { this.setState({ show: false }); }} >
                    <Modal.Header closeButton>
                        <Modal.Title>Eliminar noticia?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Estas a punto de eliminar una noticia, Estas seguro?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { this.setState({ show: false }); }}>
                            Cancelar
                        </Button>
                        <Button variant="primary" onClick={() => api_adm("eliminar_noticia", id)}>
                            Eliminar
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={show2} onHide={() => { this.setState({ show2: false }); }} size="xl">
                    <Modal.Header closeButton>
                        <Modal.Title>Editar noticia</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "1050px" }}>
                        <div>
                            <Form.File style={{ marginTop: "8px" }} onChange={this.handleChangeimagen} accept="image/*" />
                            <div style={{ marginTop: "8px" }}>
                                <Form.Label>Titulo</Form.Label>
                                <Form.Control value={titulo} onChange={this.handleChangetitulo} />
                            </div>
                            <div style={{ marginTop: "8px" }}>
                                <Form.Label>Contenido</Form.Label>
                                <Form.Control as="textarea" rows={10} value={parrafo} onChange={this.handleChangeparrafo} />
                            </div>
                            <Form.Check
                                style={{ marginTop: "8px" }}
                                checked={checked}
                                onChange={() => { this.setState({ checked: !checked }); }}
                                type="switch"
                                id="custom-switch"
                                label="incluir en portada"
                            />
                        </div>

                        <div>
                            <h5>Preview:</h5>
                            <div className="noticia_preview_container1">
                                <img alt="fallo en la imagen" src={imagen} className="noticias_imagen" />

                                <Link>
                                    <h4>{titulo}</h4>
                                </Link>
                                <p style={{ display: "flex", alignSelf: "flex-start" }}>{fecha}</p>
                                <div className="noticias_noticia_container2">
                                    <p>{parrafoc}</p>
                                    <Button variant="info">Ver mas</Button>
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { this.setState({ show2: false }); }}>
                            Cancelar
                            </Button>
                        <Button variant="primary" onClick={() => api_adm("editar_noticia", id, titulo, parrafo, imagen, checked, fecha, undefined, undefined)}>
                            Guardar
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={show3} onHide={() => { this.setState({ show3: false }); }} size="xl">
                    <Modal.Header closeButton>
                        <Modal.Title>Nueva noticia</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "1050px" }}>
                        <div>
                            <Form.File style={{ marginTop: "8px" }} onChange={this.handleChangeimagen} accept="image/*" />
                            <div style={{ marginTop: "8px" }}>
                                <Form.Label>Titulo</Form.Label>
                                <Form.Control value={titulo} onChange={this.handleChangetitulo} />
                            </div>
                            <div style={{ marginTop: "8px" }}>
                                <Form.Label>Contenido</Form.Label>
                                <Form.Control as="textarea" rows={10} value={parrafo} onChange={this.handleChangeparrafo} />
                            </div>
                            <Form.Check
                                style={{ marginTop: "8px" }}
                                checked={checked}
                                onChange={() => { this.setState({ checked: !checked }); }}
                                type="switch"
                                id="custom-switch"
                                label="incluir en portada"
                            />
                        </div>

                        <div>
                            <h5>Preview:</h5>
                            <div className="noticia_preview_container1">
                                <img alt="fallo en la imagen" src={imagen} className="noticias_imagen" />

                                <Link>
                                    <h4>{titulo}</h4>
                                </Link>
                                <p style={{ display: "flex", alignSelf: "flex-start" }}>{fecha}</p>
                                <div className="noticias_noticia_container2">
                                    <p>{parrafoc}</p>
                                    <Button variant="info">Ver mas</Button>
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { this.setState({ show3: false }); }}>
                            Cancelar
                            </Button>
                        <Button variant="primary" onClick={() => api_adm("crear_noticia", 0, titulo, parrafo, imagen, checked, fecha, undefined, undefined)}>
                            Guardar
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Button variant="primary" style={{ marginTop: "30px" }} onClick={() => {
                    var f = new Date();
                    const fecha = cfecha(f);
                    this.setState({
                        show3: true, id: 0,
                        titulo: "",
                        parrafo: "",
                        imagen: "",
                        checked: false,
                        fecha: fecha
                    });
                }}>Crear noticia</Button>
                {Tabla_adm(filarender)}
            </div>
        );
    }
}




export default Noticias_adm;