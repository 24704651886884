
import React, { useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";

import Perfil from '../componentes/variablesSesion';
import { Button, Form, Alert } from "react-bootstrap";
import Inicio_adm from './administracion/Inicio_adm';
import raw from "../p.txt";




class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contraseña: "",
            cc: "",
            show: false,
            a: false
        };
        this.handleChangecontraseña = this.handleChangecontraseña.bind(this);
    }
    componentDidMount() {
        fetch(raw)
            .then(r => r.text())
            .then(text => {
                this.setState({ cc: text });
            });
    }

    handleChangecontraseña(event) {
        this.setState({ contraseña: event.target.value });
    }

    render() {
        const { contraseña, show, cc } = this.state;
        const logeado = Perfil.logeado();
        if (logeado == "true") {
            return (
                <Redirect to="/administracion" />
            );
        } else {
            return (
                <div style={{ display: "flex", backgroundColor: "#7DBB5B", minHeight: "100vh", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                    <Alert variant="danger" onClose={() => this.setState({ show: false })} show={show} dismissible>Contraseña incorrecta!</Alert>
                    <Form style={{ display: "flex", backgroundColor: "white", padding: "50px", alignItems: "center", justifyContent: "center", flexDirection: "column", borderRadius: "4px", boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24)" }}>
                        <Form.Group controlId="formBasicPassword">
                            <Form.Control type="password" placeholder="Password" value={contraseña} onChange={this.handleChangecontraseña} />
                        </Form.Group>
                        <Button variant="primary" onClick={() => {
                            if (contraseña == cc) {
                                Perfil.cambiarlogeado("true");
                                this.setState({ a: true });

                            } else {
                                this.setState({ show: true });
                            }
                        }}>
                            Ingresar
                        </Button>
                    </Form>
                </div>
            );
        }
    }

}

export default Login;
