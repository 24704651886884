import React from "react";
import {
    BrowserRouter as Router,
    Link
} from "react-router-dom";
import { Carousel, Button, Card, Table, Form, Modal } from "react-bootstrap";
import { api_adm } from "../subcomponentes";


class Carrusel_adm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            noticias: props.noticias,
            carrusel: props.carrusel,
            show: false,
            show2: false,
            show3: false,
            id: 0,
            titulo: "",
            subtitulo: "",
            imagen: "",
            checked: false,
            fecha: "",
            direccion: ""
        };
        this.handleChangetitulo = this.handleChangetitulo.bind(this);
        this.handleChangesubtitulo = this.handleChangesubtitulo.bind(this);
        this.handleChangedireccion = this.handleChangedireccion.bind(this);
        this.handleChangeimagen = this.handleChangeimagen.bind(this);
    }
    handleChangeimagen(event) {

        for (const file of event.target.files) {
            if (file.type && file.type.indexOf('image') === -1) {
                console.log('File is not an image.', file.type, file);
                return;
            }

            const reader = new FileReader();
            reader.addEventListener('load', (event) => {
                this.setState({ imagen: event.target.result });
            });
            reader.readAsDataURL(file);
        }

    }
    handleChangetitulo(event) {
        this.setState({ titulo: event.target.value });
    }
    handleChangesubtitulo(event) {
        this.setState({ subtitulo: event.target.value });
    }
    handleChangedireccion(event) {
        this.setState({ direccion: event.target.value });
    }

    eliminarimagen(idi) {
        let carruseld = this.state.carrusel;
        const id = carruseld.indexOf(idi);
        carruseld.splice(id, 1);
        this.setState({ carrusel: carruseld });
    }

    render() {
        const { noticias, subtitulo, show, id, show2, titulo, imagen, show3, fecha, carrusel, direccion } = this.state;
        const carruselitems = carrusel.map((carrusel) => {
            return (
                <Carousel.Item interval={3000}>
                    {carrusel.direccion == "" ?
                        <div>
                            <img src={carrusel.imagen} style={{ display: "block", width: "100%", height: "30vw", minHeight: "356px", objectFit: "cover" }} />
                            <Carousel.Caption>
                                <h1 style={{ textShadow: "2px 3px 30px rgba(0,0,0,1)" }}>{carrusel.titulo}</h1>
                                <h4 style={{ textShadow: "2px 3px 30px rgba(0,0,0,1)" }}>{carrusel.subtitulo}</h4>
                            </Carousel.Caption>
                        </div>
                        :
                        <div>
                            <img src={carrusel.imagen} style={{ display: "block", width: "100%", height: "30vw", minHeight: "356px", objectFit: "cover" }} />
                            <Carousel.Caption>
                                <h1 style={{ textShadow: "2px 3px 30px rgba(0,0,0,1)" }}>{carrusel.titulo}</h1>
                                <h4 style={{ textShadow: "2px 3px 30px rgba(0,0,0,1)" }}>{carrusel.subtitulo}</h4>
                                <Button variant="info" as={Link} style={{ marginBottom: "15px" }} to={carrusel.direccion}>Mas informacion</Button>
                            </Carousel.Caption>
                        </div>
                    }
                </Carousel.Item>
            );
        });
        const carruselimg = carrusel.map((carrusel) => {
            return (

                <div style={{ position: "relative", margin: "30px" }}>
                    <Card style={{ width: '18rem', boxShadow: "10px 2px 79px 0px rgba(0,0,0,0.82)" }}>
                        <Card.Img variant="top" src={carrusel.imagen} />
                        <Card.Body>
                            <Card.Title>{carrusel.titulo}</Card.Title>
                            <Card.Text>
                                {carrusel.subtitulo}
                            </Card.Text>
                            <Card.Text>
                                direccion: {carrusel.direccion}
                            </Card.Text>
                            <div style={{ display: "flex", justifyContent: "space-around" }}>
                                <Button variant="primary" onClick={() => { this.setState({ show2: true, id: carrusel.id, titulo: carrusel.titulo, subtitulo: carrusel.subtitulo, imagen: carrusel.imagen, direccion: carrusel.direccion }); }}>Editar</Button>
                                <Button variant="danger" onClick={() => { this.setState({ show: true, id: carrusel.id }); }} >Eliminar</Button>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            );
        });

        return (
            <div className="carrusel_adm">

                <Modal show={show} onHide={() => { this.setState({ show: false }); }} >
                    <Modal.Header closeButton>
                        <Modal.Title>Eliminar noticia?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Estas a punto de eliminar un item del carrusel, Estas seguro?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { this.setState({ show: false }); }}>
                            Cancelar
                            </Button>
                        <Button variant="primary" onClick={() => api_adm("eliminar_carrusel", id)}>
                            Eliminar
                            </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={show2} onHide={() => { this.setState({ show2: false }); }} size="xl">
                    <Modal.Header closeButton>
                        <Modal.Title>Editar item</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "fit-content" }}>
                        <div>
                            <Form.Label style={{ marginTop: "15px" }}>Titulo</Form.Label>
                            <Form.Control value={titulo} onChange={this.handleChangetitulo} />
                            <Form.Label style={{ marginTop: "15px" }}>Subtitulo</Form.Label>
                            <Form.Control value={subtitulo} onChange={this.handleChangesubtitulo} />
                            <Form.Label style={{ marginTop: "15px" }}>direccion</Form.Label>
                            <Form.Control value={direccion} onChange={this.handleChangedireccion} />
                            <div style={{ margin: "8px" }}>
                                <Form.Label>imagen</Form.Label>
                                <Form.File style={{ marginTop: "8px" }} onChange={this.handleChangeimagen} accept="image/*" />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { this.setState({ show2: false }); }}>
                            Cancelar
                            </Button>
                        <Button variant="primary" onClick={() => api_adm("editar_carrusel", id, titulo, subtitulo, imagen, undefined, undefined, undefined, undefined, direccion)}>
                            Guardar
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={show3} onHide={() => { this.setState({ show3: false }); }} size="xl">
                    <Modal.Header closeButton>
                        <Modal.Title>Crear item</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "fit-content" }}>
                        <div>
                            <Form.Label style={{ marginTop: "15px" }}>Titulo</Form.Label>
                            <Form.Control value={titulo} onChange={this.handleChangetitulo} />
                            <Form.Label style={{ marginTop: "15px" }}>Subtitulo</Form.Label>
                            <Form.Control value={subtitulo} onChange={this.handleChangesubtitulo} />
                            <Form.Label style={{ marginTop: "15px" }}>direccion</Form.Label>
                            <Form.Control value={direccion} onChange={this.handleChangedireccion} />
                            <div style={{ margin: "8px" }}>
                                <Form.Label>imagen</Form.Label>
                                <Form.File style={{ marginTop: "8px" }} onChange={this.handleChangeimagen} accept="image/*" />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { this.setState({ show3: false }); }}>
                            Cancelar
                            </Button>
                        <Button variant="primary" onClick={() => api_adm("crear_carrusel", id, titulo, subtitulo, imagen, undefined, undefined, undefined, undefined, direccion)}>
                            Guardar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <div className="carrusel_adm_div1">
                    <h1>Editar carrusel</h1>
                    <Button variant="primary" style={{ marginTop: "30px" }} onClick={() => {
                        this.setState({
                            show3: true,
                            id: 0,
                            titulo: "",
                            subtitulo: "",
                            imagen: "",
                        });
                    }}>Crear item carrusel</Button>
                    <div className="carrusel_adm_div2">
                        {carruselimg}
                    </div>
                    <h1>preview:</h1>
                    <div style={{ transform: "scale(0.5)", width: "100%", boxShadow: "10px 2px 79px 0px rgba(0,0,0,0.82)" }}>
                        <Carousel className="inicio_carrusel" >
                            {carruselitems}
                        </Carousel>
                    </div>
                    <div style={{ width: "70%", marginBottom: "100px" }}>
                    </div>
                </div>
            </div>
        );
    }
}



export default Carrusel_adm;