import React from "react";
import ImageGallery from 'react-image-gallery';
import {
    BrowserRouter as Router,
    Link,
    Redirect
} from "react-router-dom";
import Imagenes from "./Imagenes";
import { getQueryVariable } from "./subcomponentes";


export default class Galeria extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            eventos: props.eventos
        };
    }

    render() {
        const eventos = this.state.eventos;
        const query = getQueryVariable("idevento");
        const fevento = eventos.filter(fxnombre);

        function fxnombre(obj) {
            if ('id' in obj && obj.id == query) {
                return true;
            } else {
                return false;
            }
        }



        const imagenrender = eventos.map((evento) => {
            return (
                <div className="galeria_evento_container">
                    <img alt="fallo en la imagen" className="galeria_evento_imagen" src={evento.imagenes[0].original} />
                    <Link to={"/galeria?idevento=" + evento.id} >
                        <h4>{evento.nombre}</h4>
                    </Link>
                </div>
            );
        });


        if (query == "") {
            return (
                <div className="galeria_fondo">
                    {imagenrender}
                </div>
            );
        } else if (fevento[0] !== undefined) {
            return (
                <Imagenes id={fevento[0].id} />
            );
        } else {
            return (
                <Redirect to="/inicio" />
            );
        }

    }
}




/*
 * eventos = [
     {
         Nombre: Evento1,
         Fecha: 10/04/2006
         imagenes=[
             https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4SiK5spRHqAKrANzigIXDmaDXoNGys8KKdQ&usqp=CAU,
             https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4SiK5spRHqAKrANzigIXDmaDXoNGys8KKdQ&usqp=CAU,
             https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4SiK5spRHqAKrANzigIXDmaDXoNGys8KKdQ&usqp=CAU,
             https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4SiK5spRHqAKrANzigIXDmaDXoNGys8KKdQ&usqp=CAU,
         ]
     }
 ]
 */