import React from "react";
import {
    BrowserRouter as Router,
    Link
} from "react-router-dom";
import { Carousel, Button } from "react-bootstrap";
import { pcortado } from "./subcomponentes";
import Galeria from "./Galeria";


class Inicio extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            noticias: props.noticias,
            carrusel: props.carrusel,
            eventos: props.eventos
        };
    }

    render() {
        const { noticias, carrusel, eventos } = this.state;
        const carruselitems = carrusel.map((carrusel) => {
            return (
                <Carousel.Item interval={3000}>
                    {carrusel.direccion == "" ?
                        <div>
                            <img src={carrusel.imagen} style={{ display: "block", width: "100%", height: "30vw", minHeight: "356px", objectFit: "cover" }} />

                            <Carousel.Caption>
                                <h1 style={{ textShadow: "2px 3px 30px rgba(0,0,0,1)" }}>{carrusel.titulo}</h1>
                                <h4 style={{ textShadow: "2px 3px 30px rgba(0,0,0,1)" }}>{carrusel.subtitulo}</h4>
                            </Carousel.Caption>
                        </div>
                        :
                        <div>
                            <img src={carrusel.imagen} style={{ display: "block", width: "100%", height: "30vw", minHeight: "356px", objectFit: "cover" }} />
                            <Carousel.Caption>
                                <h1 style={{ textShadow: "2px 3px 30px rgba(0,0,0,1)" }}>{carrusel.titulo}</h1>
                                <h4 style={{ textShadow: "2px 3px 30px rgba(0,0,0,1)" }}>{carrusel.subtitulo}</h4>
                                <Button variant="info" style={{ marginBottom: "15px" }} as={Link} to={carrusel.direccion}>Mas informacion</Button>
                            </Carousel.Caption>
                        </div>
                    }
                </Carousel.Item>
            );
        });
        const noticiasrender = noticias.map((noticia) => {
            if (noticia.inicio == 1) {
                const parrafo = pcortado(noticia.parrafo);
                return (
                    <div key={noticia.id} className="noticias_noticia_container1">
                        <img alt="fallo en la imagen" src={noticia.imagen} className="noticias_imagen" />

                        <Link to={"/noticias?noticia=" + noticia.id} onClick={window.scrollTo(0, 0)}>
                            <h4>{noticia.titulo}</h4>
                        </Link>
                        <p style={{ display: "flex", alignSelf: "flex-start" }}>{noticia.fecha}</p>
                        <div className="noticias_noticia_container2">
                            <p>{parrafo}</p>
                            <Button variant="info" as={Link} to={"/noticias?noticia=" + noticia.id} onClick={window.scrollTo(0, 0)}>Ver mas</Button>
                        </div>
                    </div>
                );
            }
        });
        //backgroundImage:"url('http://centroangelitos.cl/Sin%20t%C3%ADtulo%20%283%29.png');"
        return (
            <div className="inicio_fondo">
                <Carousel className="inicio_carrusel" >
                    {carruselitems}
                </Carousel>
                <div style={{ display: "flex", backgroundImage: "url('http://centroangelitos.cl/i.png')", flex: 1, minHeight: "50px" }} />
                <div className="inicio_pv_noticias">
                    <h3><Link to="/noticias" className="cnegro">Noticias</Link></h3>
                    <div className="inicio_noticias">
                        {noticiasrender}
                    </div>
                </div>
                <div style={{ display: "flex", backgroundImage: "url('http://centroangelitos.cl/i.png')", flex: 1, minHeight: "50px" }} />
                <div className="inicio_pv_galeria">
                    <h3><Link to="/galeria" className="cnegro">Galeria</Link></h3>
                    <Galeria eventos={eventos} />
                </div>
                <div style={{ display: "flex", backgroundImage: "url('http://centroangelitos.cl/i.png')", flex: 1, minHeight: "50px" }} />
            </div>
        );
    }
}



export default Inicio;



/*https://www.youtube.com/watch?v=UNP03fDSj1U*/
/*                 */