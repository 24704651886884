import React, { useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";

import Perfil from './variablesSesion';
import { Button, Form, Alert } from "react-bootstrap";
import Inicio_adm from './administracion/Inicio_adm';
import mision from "../Mision.txt";
import vision from "../Vision.txt";
import sello from "../Sello.txt";


class Mision_vision_sello extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mision: "",
            vision: "",
            sello: "",
            show: false,
        };
    }
    componentDidMount() {
        fetch(mision)
            .then(r => r.text())
            .then(text => {
                this.setState({ mision: text });
            });
        fetch(vision)
            .then(r => r.text())
            .then(text => {
                this.setState({ vision: text });
            });
        fetch(sello)
            .then(r => r.text())
            .then(text => {
                this.setState({ sello: text });
            });
    }

    render() {
        const { mision, vision, sello } = this.state;

        return (
            <div className="v_fondo">
                <div className="mvs_contenedor">
                    <h1 className="mvs_centrado">Mision vision y sello</h1>
                </div>

                <div className="v_container">
                    <h4>Misión institucional</h4>
                        <p style={{ whiteSpace: "pre-wrap", fontSize: "70%", textAlign: "justify" }}>{mision}</p>
                    <br />
                    <h4>Visión de Futuro</h4>
                        <p style={{ whiteSpace: "pre-wrap", fontSize: "70%", textAlign: "justify" }}>{vision}</p>
                    <br />
                    <h4>Identidad y sellos del establecimiento</h4>
                    <br />
                    <div style={{width:"100%", textAlign:"justify"}}>
                        <ul style={{ whiteSpace: "pre-wrap", fontSize: "70%", textAlign: "justify" }}>
                            <li>Ser un establecimiento altamente especializado en educación especial y sus manifestaciones.</li>
                            <br />
                            <li>Caracterizada por una atención eficiente, de calidad técnica y con acogida a las familias.</li>
                            <br />
                            <li>Con un sello artístico que potencie las características de nuestros alumnos y alumnas</li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }

}

export default Mision_vision_sello;
