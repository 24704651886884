import React from "react";
import {
    BrowserRouter as Router,
    Link
} from "react-router-dom";
import { Table, Form, Modal, Button, Alert, Spinner } from "react-bootstrap";
import { Tabla_adm, cfecha, api_adm } from "../subcomponentes";
const axios = require('axios');


class Galeria_adm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            eventos: props.eventos,
            show: false,
            c: 0,
            id: 0,
            nombre: "",
            fecha: "",
            imagenes: [],
            show2: false,
            show3: false,
            isLoaded: true,
            showalert: false
        };
        this.handleChangeimagen = this.handleChangeimagen.bind(this);
        this.handleChangenombre = this.handleChangenombre.bind(this);
    }
    handleChangeimagen(event) {
        let imagenes = this.state.imagenes;

        for (const file of event.target.files) {
            if (file.type && file.type.indexOf('image') === -1) {
                console.log('File is not an image.', file.type, file);
                return;
            }

            const reader = new FileReader();
            reader.addEventListener('load', (event) => {

                if (this.state.imagenes.length < 30) {
                    imagenes.push(event.target.result);
                    this.setState({ c: 0 });
                    console.log(event.target.result);
                } else {
                    this.setState({ showalert: true });
                }
            });
            reader.readAsDataURL(file);
        }
    }
    handleChangenombre(event) {
        this.setState({ nombre: event.target.value });
    }
    eliminarimagen(imagen) {
        let imagenesd = this.state.imagenes;
        const id = imagenesd.indexOf(imagen);
        imagenesd.splice(id, 1);
        this.setState({ imagenes: imagenesd });
    }
    datosimagen(evento) {
        const imagenes = [];
        const id = evento.id;
        this.setState({
            isLoaded: false,
            show2: true,
            id: evento.id,
            nombre: evento.nombre,
            fecha: evento.fecha,
        });
        const data = {
            id: id,
        };
        axios({
            method: "post",
            url: "https://centroangelitos.cl/api/api_imagenes.php",
            data: JSON.stringify(data),
        }).then(json => {
            json.data.datos[0].imagenes.map((imagen) => { imagenes.push(imagen.original); });
            this.setState({ imagenes: imagenes, isLoaded: true });
        })
            .catch((error) => { console.log(error); });

    }

    componentDidMount() {
    }

    render() {
        var f = new Date();

        const fecha = cfecha(f);
        const { eventos, show, id, show3, imagenes, nombre, show2, isLoaded, showalert } = this.state;
        const filarender = eventos.map((evento) => {
            return (
                <tr>
                    <td>{evento.nombre}</td>
                    <td>{evento.id}</td>
                    <td>{evento.fecha}</td>
                    <td>
                        <img onClick={() => { this.datosimagen(evento); }} style={{ cursor: "pointer", width: "20px" }} src="https://www.pngkit.com/png/full/94-943587_este-grficos-es-editar-el-icono-sobre-editores.png" />
                    </td>
                    <td>
                        <img onClick={() => { this.setState({ show: true, id: evento.id }); }} style={{ cursor: "pointer", width: "20px" }} src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5f/Red_X.svg/1024px-Red_X.svg.png" />
                    </td>
                </tr>
            );
        });

        const imagenespv = imagenes.map((imagen) => {
            return (
                <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", position: "relative", width: "fit-content", backgroundColor: "blue" }}>
                    <img style={{ top: "0px", left: "0px", position: "relative", height: "200px", margin: "8px", zIndex: 1 }} src={imagen} />
                    <img onClick={() => { this.eliminarimagen(imagen); }} style={{ top: "15px", right: "15px", position: "absolute", zIndex: 3, cursor: "pointer", width: "20px", }} src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5f/Red_X.svg/1024px-Red_X.svg.png" />
                </div>
            );
        });
        return (
            <div className="fondo_galeria_adm" >
                <Modal show={show} onHide={() => { this.setState({ show: false }); }} >
                    <Modal.Header closeButton>
                        <Modal.Title>Eliminar evento?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Estas a punto de eliminar un evento, Estas seguro?
                </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { this.setState({ show: false }); }}>
                            Cancelar
                        </Button>
                        <Button variant="primary" onClick={() => api_adm("eliminar_evento", id)}>
                            Eliminar
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={show2} onHide={() => { this.setState({ show2: false }); }} size="xl">
                    <Modal.Header closeButton>
                        <Modal.Title>Editar evento</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "fit-content" }}>
                        <div>
                            <Alert variant="danger" onClose={() => this.setState({ showalert: false })} show={showalert} dismissible>El maximo de imagenes por evento es 30 (solo cargaron las primeras 30 imagenes)</Alert>
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control value={nombre} onChange={this.handleChangenombre} />
                            <div style={{ margin: "8px" }}>
                                <Form.File multiple style={{ marginTop: "8px" }} onChange={this.handleChangeimagen} accept="image/*" />
                            </div>
                            {isLoaded == true ?
                                <div>
                                    {imagenespv}
                                </div>
                                :
                                <div style={{ display: "flex", height: "300px", alignItems: "center", justifyContent: "center" }}>
                                    <Spinner animation="border" role="status" variant="dark" />
                                </div>
                            }
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { this.setState({ show2: false, showalert: false }); }}>
                            Cancelar
                        </Button>
                        <Button variant="primary" onClick={() => {
                            api_adm("editar_evento", id, undefined, undefined, undefined, undefined, fecha, imagenes, nombre);
                            this.setState({ isLoaded: false });
                        }}>
                            Guardar
                    </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={show3} onHide={() => { this.setState({ show3: false }); }} size="xl">
                    <Modal.Header closeButton>
                        <Modal.Title>Nuevo evento</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "fit-content" }}>
                        <div>
                            <Alert variant="danger" onClose={() => this.setState({ showalert: false })} show={showalert} dismissible>El maximo de imagenes por evento es 30 (solo cargaron las primeras 30 imagenes)</Alert>
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control value={nombre} onChange={this.handleChangenombre} />
                            <div style={{ margin: "8px" }}>
                                <Form.File multiple style={{ marginTop: "8px" }} onChange={this.handleChangeimagen} accept="image/*" />
                            </div>
                            {imagenespv}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { this.setState({ show3: false, imagenes: [], showalert: false }); }}>
                            Cancelar
                        </Button>
                        <Button variant="primary" onClick={() => {
                            api_adm("crear_evento", 0, undefined, undefined, undefined, undefined, fecha, imagenes, nombre);
                            this.setState({ isLoaded: false });
                        }}>
                            Guardar
                    </Button>
                    </Modal.Footer>
                </Modal>
                <Button variant="primary" style={{ marginTop: "30px" }} onClick={() => { this.setState({ id: 0, nombre: "", fecha: fecha, show3: true, imagenes: [], showalert: false }); }} >Nuevo evento</Button>
                {Tabla_adm(filarender, "Nombre")}
            </div>
        );
    }


}



export default Galeria_adm;