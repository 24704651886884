import React from "react";
import {
    BrowserRouter as Router,
    Link
} from "react-router-dom";
import { Table, Form, Modal, Button } from "react-bootstrap";
import { cnivel, getQueryVariable, pcortado, api_adm, Tabla_adm } from "../subcomponentes";
import Personal from "../Personal";
const axios = require('axios');

class Personal_adm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            personal: [],
            show: false,
            show2: false,
            show3: false,
            nombre: "",
            cargo: "",
            nivel: 0,
            imagen: "",
        };
        this.handleChangenombre = this.handleChangenombre.bind(this);
        this.handleChangenivel = this.handleChangenivel.bind(this);
        this.handleChangecargo = this.handleChangecargo.bind(this);
        this.handleChangeimagen = this.handleChangeimagen.bind(this);
    }
    handleChangenombre(event) {
        this.setState({ nombre: event.target.value });
    }
    handleChangenivel(event) {
        this.setState({ nivel: event.target.value });
    }
    handleChangecargo(event) {
        this.setState({ cargo: event.target.value });
    }
    handleChangeimagen(event) {

        for (const file of event.target.files) {
            if (file.type && file.type.indexOf('image') === -1) {
                console.log('File is not an image.', file.type, file);
                return;
            }

            const reader = new FileReader();
            reader.addEventListener('load', (event) => {
                this.setState({ imagen: event.target.result });
            });
            reader.readAsDataURL(file);
        }

    }
    componentDidMount() {
        axios({
            method: "get",
            url: "https://centroangelitos.cl/api/api_personal.php",
        }).then(json => {
            const personal = json.data.datos;
            this.setState({ personal: personal });
            this.setState({ isLoaded: true });
        })
            .catch((error) => { console.log(error); });
    }

    render() {

        const query = getQueryVariable("personal");
        const { personal, cargo, show, id, show2, nombre, imagen, show3, nivel } = this.state;



        console.log(imagen);


        const filarender = personal.map((personal) => {
            return (
                <tr>
                    <td>{personal.nombre}</td>
                    <td>{personal.cargo}</td>
                    <td>{personal.nivel}</td>
                    <td>
                        <img onClick={() => { this.setState({ show2: true, id: personal.id, cargo: personal.cargo, nombre: personal.nombre, nivel: personal.nivel, imagen: personal.imagen }); }} style={{ cursor: "pointer", width: "20px" }} src="https://www.pngkit.com/png/full/94-943587_este-grficos-es-editar-el-icono-sobre-editores.png" />
                    </td>
                    <td>
                        <img onClick={() => { this.setState({ show: true, id: personal.id }); }} style={{ cursor: "pointer", width: "20px" }} src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5f/Red_X.svg/1024px-Red_X.svg.png" />
                    </td>
                </tr>
            );
        });


        return (
            <div className="fondo_noticias_adm" >
                <Modal show={show} onHide={() => { this.setState({ show: false }); }} >
                    <Modal.Header closeButton>
                        <Modal.Title>Eliminar personal?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Estas a punto de eliminar una personal, Estas seguro?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { this.setState({ show: false }); }}>
                            Cancelar
                        </Button>
                        <Button variant="primary" onClick={() => api_adm("eliminar_personal", id)}>
                            Eliminar
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={show2} onHide={() => { this.setState({ show2: false }); }} size="xl">
                    <Modal.Header closeButton>
                        <Modal.Title>Editar personal</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "fit-content" }}>
                        <div>
                            <Form.File style={{ marginTop: "8px" }} onChange={this.handleChangeimagen} accept="image/*" />
                            <div style={{ marginTop: "8px" }}>
                                <Form.Label>Nombre</Form.Label>
                                <Form.Control value={nombre} onChange={this.handleChangenombre} />
                            </div>
                            <div style={{ marginTop: "8px" }}>
                                <Form.Label>Contenido</Form.Label>
                                <Form.Control as="textarea" rows={10} value={cargo} onChange={this.handleChangecargo} />
                            </div>
                            <Form.Label>Nivel</Form.Label>
                            <Form.Control type="number" value={nivel} onChange={this.handleChangenivel} />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { this.setState({ show2: false }); }}>
                            Cancelar
                        </Button>
                        <Button variant="primary" onClick={() => api_adm("editar_personal", id, nivel, cargo, undefined, undefined, undefined, undefined, nombre, imagen)}>
                            Guardar
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={show3} onHide={() => { this.setState({ show3: false }); }} size="xl">
                    <Modal.Header closeButton>
                        <Modal.Title>Nueva personal</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "fit-content" }}>
                        <div>
                            <Form.File style={{ marginTop: "8px" }} onChange={this.handleChangeimagen} accept="image/*" />
                            <div style={{ marginTop: "8px" }}>
                                <Form.Label>Nombre</Form.Label>
                                <Form.Control value={nombre} onChange={this.handleChangenombre} />
                            </div>
                            <div style={{ marginTop: "8px" }}>
                                <Form.Label>Contenido</Form.Label>
                                <Form.Control as="textarea" rows={10} value={cargo} onChange={this.handleChangecargo} />
                            </div>
                            <Form.Label>Nivel</Form.Label>
                            <Form.Control type="number" value={nivel} onChange={this.handleChangenivel} />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { this.setState({ show3: false }); }}>
                            Cancelar
                            </Button>
                        <Button variant="primary" onClick={() => api_adm("crear_personal", 0, nivel, cargo, undefined, undefined, undefined, undefined, nombre, imagen)}>
                            Guardar
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Button variant="primary" style={{ marginTop: "30px" }} onClick={() => {
                    this.setState({
                        show3: true, id: 0,
                        nombre: "",
                        cargo: "",
                        imagen: "",
                        nivel: nivel
                    });
                }}>Crear personal</Button>
                {Tabla_adm(filarender, "Nombre", <th>Cargo</th>, <th>Nivel</th>)}
            </div>
        );
    }
}




export default Personal_adm;