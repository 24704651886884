import React, { useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";

import Perfil from '../componentes/variablesSesion';
import { Button, Form, Alert } from "react-bootstrap";
import Inicio_adm from './administracion/Inicio_adm';
import raw from "../Sello.txt";
import { Cargando } from './subcomponentes';
const axios = require('axios');


class Personal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            personal: {},
            show: false,
        };
    }
    componentDidMount() {

        axios({
            method: "get",
            url: "https://centroangelitos.cl/api/api_personal.php",
        }).then(json => {
            const personal = json.data.datos;
            this.setState({ personal: personal });
            this.setState({ isLoaded: true });
        })
            .catch((error) => { console.log(error); });
    }

    render() {
        const { personal, isLoaded } = this.state;
        if (isLoaded === true) {
            const personalnv = [];

            personal.map((personal) => { personalnv.push(personal.nivel); });

            let result = personalnv.filter((item, index) => {
                return personalnv.indexOf(item) === index;
            });
            result.sort();




            const rendercolumna = result.map((personalnv) => {
                console.log(personalnv);
                return (
                    <div className="personal_fila">
                        {this.state.personal.map((personal) => {
                            if (personal.nivel === personalnv) {
                                return (
                                    <div className="personal_box">
                                        <img style={{ height: "180px", width: "220px", objectFit: "cover" }} src={personal.imagen}></img>
                                        <h5>{personal.nombre}</h5>
                                        <h6>{personal.cargo}</h6>
                                    </div>
                                );
                            }
                        })}
                    </div>
                );

            });


            return (
                <div className="personal_fondo">
                    {rendercolumna}
                </div>
            );
        } else return (
            <Cargando />
        );

    }

}

export default Personal;
