import React from "react";
import ImageGallery from 'react-image-gallery';
import {
    BrowserRouter as Router,
    Link,
    Redirect
} from "react-router-dom";
import { Table, Form, Modal, Button, Alert, Spinner } from "react-bootstrap";
import { Cargando, getQueryVariable, Tabla_adm, api_adm } from "../subcomponentes";
const axios = require('axios');

export default class PDFs_adm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            show: false,
            pdf: props.pdf,
            link: "",
            id: 0
        };
        this.handlechangepdf = this.handlechangepdf.bind(this);
    }
    componentDidMount() {
        /*
        ? hacer una sola llamada al api por el pdf del curso especifico?
        const id = this.state.id
        const data = {
            id: id,
        }
        axios({
            method: "post",
            url: "https://centroangelitos.cl/api/api_imagenes.php",
            data: JSON.stringify(data),
        }).then(json => {
            const evento = json.data.datos[0]
            this.setState({ evento: evento })
            this.setState({ isLoaded: true })
        })
            .catch((error) => { console.log(error) })
        */

    }
    handlechangepdf(event) {

        for (const file of event.target.files) {
            if (file.type && file.type.indexOf('pdf') === -1) {
                console.log('File is not an pdf.', file.type, file);
                return;
            }

            const reader = new FileReader();
            reader.addEventListener('load', (event) => {
                this.setState({ link: event.target.result });
                console.log(event.target.result);
            });
            reader.readAsDataURL(file);
        }

    }

    datospdf(pdf) {
        this.setState({
            show: true,
            id: pdf.id,
            link: pdf.link
        });
    }


    render() {
        const { value, show, id, pdf, link } = this.state;


        const filarender = pdf.map((pdf) => {
            return (
                <tr>
                    <td>{pdf.nombre}</td>
                    <td>{pdf.id}</td>
                    <td>
                        <img onClick={() => this.datospdf(pdf)} style={{ cursor: "pointer", width: "20px" }} src="https://www.pngkit.com/png/full/94-943587_este-grficos-es-editar-el-icono-sobre-editores.png" />
                    </td>
                </tr>
            );
        });
        return (
            <div className="fondo_noticias_adm" >
                <Modal size="lg" show={show} onHide={() => { this.setState({ show: false }); }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Editar lista de utiles?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ display: "flex", flexDirection: "column", justifyContent: "space-around", height: "1100px" }}>
                        <Form.File style={{ marginTop: "8px" }} onChange={this.handlechangepdf} accept="application/pdf" />
                        <iframe style={{ width: "100%", height: "1000px" }} src={link} ></iframe>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { this.setState({ show: false }); }}>
                            Cancelar
                        </Button>
                        <Button variant="primary" onClick={() => api_adm("editar_pdf", id, undefined, undefined, undefined, undefined, undefined, undefined, undefined, link)}>
                            Guardar
                        </Button>
                    </Modal.Footer>
                </Modal>
                {Tabla_adm(filarender, "Nombre", undefined, "", undefined, "")}
            </div>

        );
    }
}