import React from "react";
import Logo from "../angelitoslogo.png";
import Phone from "../Phone.png";
import Gmail from "../Gmail_Icon.svg";
import Instagram from "../instagram.png";
import Facebook from "../facebook.png";
import whatsapp from "../WhatsApp.svg";
import {
    BrowserRouter as Router, Link
} from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";


export default class Layout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pdf: props.pdf,
            dropdown1: false,
            dropdown2: false,
            dropdown3: false,
            dropdown4: false,
            active: false
        };
    }
    dropdownfunction = (dropdown) => {
        this.setState({ dropdown1: false });
        this.setState({ dropdown2: false });
        this.setState({ dropdown3: false });
        this.setState({ dropdown4: false });
        this.setState({ active: false });

        if (dropdown == "dropdown1") {
            this.setState({ dropdown1: true });
        }
        else if (dropdown == "dropdown2") {
            this.setState({ dropdown2: true });
        }
        else if (dropdown == "dropdown3") {
            this.setState({ dropdown3: true });
        }
        else if (dropdown == "dropdown4") {
            this.setState({ dropdown4: true });
        } else if (dropdown == "dropdown1t") {
            this.setState({ dropdown1: true });
        }
        else if (dropdown == "dropdown2t") {
            this.setState({ dropdown2: true });
        }
        else if (dropdown == "dropdown3t") {
            this.setState({ dropdown3: true });
        }
        else if (dropdown == "dropdown4t") {
            this.setState({ dropdown4: true });
        }
    };
    render() {
        const { dropdown1, dropdown2, pdf, active } = this.state;
        const itempdf = pdf.map((pdf) => {
            return (
                <div>
                    <NavDropdown.Item active={active} href={pdf.link} target="_blank">{pdf.nombre}</NavDropdown.Item>
                </div>
            );
        });

        return (
            <div className="layout_fondo">
                <div className="inicio_fondo_logo" onMouseEnter={() => this.dropdownfunction("no")}>
                    <Link to="/inicio">
                        <img alt="fallo en la imagen" src={Logo} className="inicio_logo" />
                    </Link>

                    <div className="inicio_logo_texto">
                        <div className="inicio_logo_subtexto">
                            <img alt="fallo en la imagen" src={whatsapp} className="inicio_wtsp" />+56 9 9226 7050
                        </div>
                        <div className="inicio_logo_subtexto">
                            <img alt="fallo en la imagen" src={Phone} className="inicio_phone" />432-236128
                        </div>
                        <div className="inicio_logo_subtexto">
                            <img alt="fallo en la imagen" src={Gmail} className="inicio_phone" /><a style={{ color: "black" }} href="mailto:angelitos831@gmail.com">angelitos831@gmail.com</a>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", flex: 1, justifyContent: "center" }}>
                            <a href="https://www.instagram.com/angelitosescuelaespecial/" target="_blank">
                                <img style={{ display: "flex", marginRight: "10px", marginLeft: "10px", width: "30px", height: "30px", alignItems: "center" }} src={Instagram} />
                            </a>
                            <a href="https://www.facebook.com/E-E-Angelitos-1531330550495289" target="_blank">
                                <img style={{ display: "flex", marginRight: "10px", marginLeft: "10px", width: "40px", alignItems: "center" }} src={Facebook} />
                            </a>
                        </div>

                    </div>
                </div>

                <Navbar bg="prueba" variant="light" expand="sm" >
                    <Nav className="mr-auto">
                        <Nav.Link as={Link} to="/inicio"> Inicio </Nav.Link>
                        <div onMouseLeave={() => this.dropdownfunction()}>
                            <NavDropdown title="Nosotros" id="nav-dropdown" onMouseEnter={() => this.dropdownfunction("dropdown1")} onClick={() => this.dropdownfunction("dropdown1")} show={dropdown1}>
                                <NavDropdown.Item active={active} as={Link} to="/mision-vision-sello" >Mision, Vision y Sello</NavDropdown.Item>
                                <NavDropdown.Item active={active} as={Link} to="/personal">Colaboradores</NavDropdown.Item>
                                {itempdf}
                            </NavDropdown>
                        </div>
                        <div onMouseLeave={() => this.dropdownfunction()}>
                            <NavDropdown title="Material de apoyo" id="basic-nav-dropdown" onMouseEnter={() => this.dropdownfunction("dropdown2")} onClick={() => this.dropdownfunction("dropdown2")} show={dropdown2}>
                                <NavDropdown.Item as={Link} to="/tips" >Consejos para padres y apoderados</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/lutiles">Lista de Útiles</NavDropdown.Item>
                            </NavDropdown>
                        </div>
                        <Nav.Link as={Link} to="/noticias"> Noticias </Nav.Link>
                        <Nav.Link as={Link} to="/galeria"> Galería </Nav.Link>
                    </Nav>
                </Navbar>
                <div>
                    {this.props.children}
                </div>
                <div className="pie_pagina_fondo">
                    <div className="pie_pagina_fondo_sub1">
                        <img src={Logo} style={{ width: "250px" }}></img>
                        <table>
                            <tr>
                                <td style={{ width: "100px" }}>Dirección</td>
                                <td style={{ width: "20px" }}>:</td>
                                <td>Alcazar 368</td>
                            </tr>
                            <tr>
                                <td>Código postal</td>
                                <td>:</td>
                                <td>4441292</td>
                            </tr>
                            <tr>
                                <td>Teléfono</td>
                                <td>:</td>
                                <td>+56 43 2236 128</td>
                            </tr>
                            <tr>
                                <td>Instagram</td>
                                <td>:</td>
                                <td>@angelitosescuelaespecial</td>
                            </tr>
                            <tr>
                                <td>Facebook</td>
                                <td>:</td>
                                <td>E. E. Angelitos</td>
                            </tr>
                        </table>


                    </div>
                    <div className="pie_pagina_fondo_sub2">
                        <div style={{ display: "flex", width: "100%", height: "100%", alignItems: "center", justifyContent: "center" }}>
                            <iframe title="mapa" src="https://maps.google.com/?q=-37.47577777734953,-72.36239739010226&z=16&t=m&output=embed" className="mapa"></iframe>
                        </div>

                        <div style={{ display: "flex", minHeight: "fit-content", width: "100%", bottom: "0%", borderTop: "3px solid white", borderBottom: "3px solid white", color: "white", flexDirection: "column" }}>
                            © 2021 Corporación Educacional Angelitos. Todos los derechos reservados.
                            <div>
                                Desarrollado por <a href="mailto:agus.wolfthinks@gmail.com">Wolfthinks</a>
                            </div>

                        </div>
                    </div>
                    <div className="pie_pagina_fondo_sub3">
                        <Link to="/administracion">Administracion</Link>
                    </div>

                </div>
            </div>
        );
    }
}