import React from "react";
import {
    BrowserRouter as Router,
    Link,
    Redirect
} from "react-router-dom";
import { Button } from "react-bootstrap";
import { getQueryVariable, pcortado } from "./subcomponentes";


export default class Noticias extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            noticias: props.noticias
        };
    }

    render() {
        const noticias = this.state.noticias;

        const query = getQueryVariable("noticia");
        const fnoticia = noticias.filter(fxnombre);
        function fxnombre(obj) {
            if ('id' in obj && obj.id == query) {
                return true;
            } else {
                return false;
            }
        }


        const noticiasrender = noticias.map((noticia) => {
            const parrafo = pcortado(noticia.parrafo);
            return (
                <div key={noticia.id} className="noticias_noticia_container1">
                    <div className="container_img_noticia">
                        <div className="container_im_noticia">
                            <img src={noticia.imagen} className="noticias_imagen" />
                        </div>
                    </div>



                    <Link to={"/noticias?noticia=" + noticia.id} onClick={window.scrollTo(0, 0)}>
                        <h4>{noticia.titulo}</h4>
                    </Link>
                    <p style={{ display: "flex", alignSelf: "flex-start" }}>{noticia.fecha}</p>
                    <div className="noticias_noticia_container2">
                        <p>{parrafo}</p>
                        <Button variant="info" as={Link} to={"/noticias?noticia=" + noticia.id} onClick={window.scrollTo(0, 0)}>Ver mas</Button>
                    </div>
                </div>
            );
        });


        if (query == "") {
            return (
                <div className="noticias_fondo">
                    {noticiasrender}
                </div>
            );
        } else if (fnoticia[0].titulo != undefined) {
            return (
                <div className="noticias_fondo">
                    <div className="noticia_container">
                        <img src={fnoticia[0].imagen} hspace="5" vspace="5" className="noticia_imagen"></img>
                        <h1 style={{ color: "#1A8BFF" }}>{fnoticia[0].titulo}</h1>
                        <p style={{ fontSize: "80%", whiteSpace: "pre-wrap" }}>{fnoticia[0].fecha}</p>
                        {fnoticia[0].parrafo}
                    </div>
                </div>
            );
        }
        else {
            return (
                <Redirect to="/inicio" />
            );
        }

    }
}




