import React from "react";
import {
    BrowserRouter as Router,
    Link
} from "react-router-dom";



class Inicio_adm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        const Modulo = (link, nombre) => {
            return (
                <Link to={link}>
                    <div className="modulo_adm">
                        <h1 style={{ textAlign: "center" }}>{nombre}</h1>
                    </div>
                </Link>
            );
        };

        return (
            <div className="inicio_adm">
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {Modulo("/egaleria", "galeria")}
                    {Modulo("/enoticias", "noticias")}
                    {Modulo("/ecarrusel", "carrusel")}
                </div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {Modulo("/etips", "tips")}
                    {Modulo("/eutiles", "Lista de utiles (PDFs)")}
                    {Modulo("/epdf", "PDFs")}
                </div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {Modulo("/epersonal", "personal")}
                </div>


            </div>
        );
    }
}



export default Inicio_adm;