import { Table, Form, Modal, Button, Spinner } from "react-bootstrap";

export function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
            return pair[1];
        }
    }
    return false;
}

export function pcortado(parrafo) {
    if (parrafo.length > 160) {
        const nparrafo = parrafo.substring(0, 160);
        const n = nparrafo.lastIndexOf(" ");
        let fparrafo = nparrafo.substring(0, n);
        return fparrafo = fparrafo + "...";

    } else {
        return parrafo;
    }
}
export function cfecha(fecha) {
    if (fecha.getDate() < 10 && (fecha.getMonth() + 1) < 10) {
        return "0" + fecha.getDate() + "/0" + (fecha.getMonth() + 1) + "/" + fecha.getFullYear();

    } else if (fecha.getDate() < 10 && (fecha.getMonth() + 1) > 9) {
        return "0" + fecha.getDate() + "/" + (fecha.getMonth() + 1) + "/" + fecha.getFullYear();

    } else if (fecha.getDate() > 9 && (fecha.getMonth() + 1) < 10) {
        return fecha.getDate() + "/0" + (fecha.getMonth() + 1) + "/" + fecha.getFullYear();

    } else {
        return fecha.getDate() + "/" + (fecha.getMonth() + 1) + "/" + fecha.getFullYear();
    }
}
export function api_adm(acccion, id, titulo, parrafo, imagen, inicio, fecha, imagenes, nombre, direccion) {
    if (inicio == true) {
        inicio = 1;
    } else if (inicio == false) {
        inicio = 0;
    }
    const data = {
        accion: acccion,
        id: id,
        titulo: titulo,
        parrafo: parrafo,
        imagen: imagen,
        fecha: fecha,
        inicio: inicio,
        imagenes: imagenes,
        nombre: nombre,
        direccion: direccion
    };
    console.log(data);
    fetch("https://centroangelitos.cl/api/api_adm_angelitos.php", {
        method: 'POST',
        body: JSON.stringify(data),
    })
        .then((response) => response.json())
        .then((json) => {
            console.log(json);
            window.location.reload();
        })
        .catch((error) => {
            console.log(error);
        });
}
export function Tabla_adm(fila, titulo = "Titulo", th2 = <th>id</th>, th3 = <th>Fecha</th>, th4 = <th>Editar</th>, th5 = <th>Eliminar</th>) {
    return (
        <Form>
            <div className="tabla_adm">
                <Table striped={true} bordered hover size="sm" style={{ width: "90vw" }} >
                    <thead>
                        <tr>
                            <th>{titulo}</th>
                            {th2}
                            {th3}
                            {th4}
                            {th5}
                        </tr>
                    </thead>
                    <tbody>
                        {fila}
                    </tbody>
                </Table>
            </div>
        </Form>
    );
}
export function Cargando() {
    return (
        <div style={{ display: "flex", height: "100vh", backgroundColor: "gray", alignItems: "center", justifyContent: "center" }}>
            <Spinner animation="border" role="status" variant="light" style={{ height: "100px", width: "100px" }}>
                <span className="sr-only">Loading...</span>
            </Spinner>
        </div>
    );
}