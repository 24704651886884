import React from "react";
import ImageGallery from 'react-image-gallery';
import {
    BrowserRouter as Router,
    Link,
    Redirect
} from "react-router-dom";
import { Cargando } from "./subcomponentes";
const axios = require('axios');

class Imagenes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.id,
            evento: [],
            isLoaded: false
        };
    }
    componentDidMount() {
        const id = this.state.id;
        const data = {
            id: id,
        };
        axios({
            method: "post",
            url: "https://centroangelitos.cl/api/api_imagenes.php",
            data: JSON.stringify(data),
        }).then(json => {
            const evento = json.data.datos[0];
            this.setState({ evento: evento });
            this.setState({ isLoaded: true });
        })
            .catch((error) => { console.log(error); });
    }


    render() {
        const { evento, isLoaded } = this.state;



        if (isLoaded == true) {
            const ig = evento.imagenes.map(function (e) {
                return {
                    original: e.original,
                    thumbnail: e.original,
                };
            });
            return (
                <div className="imagenes_fondo">
                    <h4>{evento.nombre}</h4>

                    <div className="galeria_fondo">
                        <ImageGallery items={ig} showIndex={true} thumbnailPosition="bottom" disableThumbnailScroll={false} additionalClass="app-image-gallery" showBullets={true} />
                    </div>
                </div>

            );
        } else return (
            <Cargando />
        );


    }
}

export default Imagenes;