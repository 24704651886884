import React from "react";
import {
    BrowserRouter as Router,
    Link
} from "react-router-dom";
import { Table, Form, Modal, Button, Card } from "react-bootstrap";
import { cfecha, getQueryVariable, pcortado, api_adm, Tabla_adm } from "./subcomponentes";


class Tips extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tips: props.tips,
        };

    }


    render() {
        const tips = this.state.tips


        const render = tips.map((tips) => {
            if (tips.tipo == "pdf") {
                return (
                    <a target="_blank" href={tips.parrafo}>
                        <Card style={{ minWidth: '300px', margin: "30px", alignItems: "center", paddingTop: "10px" }}>
                            <Card.Title>{tips.titulo}</Card.Title>
                            <iframe style={{ minWidth: '300px', maxWidth: "350px", height: "480px" }} src={tips.parrafo} ></iframe>
                        </Card>
                    </a>
                )
            } else {
                return (
                    <Card style={{ minWidth: '300px', width: "300px", margin: "30px" }}>
                        <Card.Body>
                            <Card.Title>{tips.titulo}</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">{tips.fecha}</Card.Subtitle>
                            <Card.Text style={{ whiteSpace: "pre-wrap" }}>
                                {tips.parrafo}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                );
            }
        });

        return (
            <div className="noticias_fondo" >
                {render}
            </div>
        );
    }
}




export default Tips;