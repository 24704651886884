import React from "react";
import {
    BrowserRouter as Router,
    Link
} from "react-router-dom";
import { Table, Form, Modal, Button } from "react-bootstrap";
import { cfecha, getQueryVariable, pcortado, api_adm, Tabla_adm } from "../subcomponentes";


class Tips_adm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tips: props.tips,
            show: false,
            show2: false,
            show3: false,
            id: 0,
            titulo: "",
            parrafo: "",
            fecha: "",
            pdf: false
        };
        this.handleChangetitulo = this.handleChangetitulo.bind(this);
        this.handleChangeparrafo = this.handleChangeparrafo.bind(this);
        this.handlechangepdf = this.handlechangepdf.bind(this);
    }
    handleChangetitulo(event) {
        this.setState({ titulo: event.target.value });
    }
    handleChangeparrafo(event) {
        this.setState({ parrafo: event.target.value });
    }
    handlechangepdf(event) {

        for (const file of event.target.files) {
            if (file.type && file.type.indexOf('pdf') === -1) {
                console.log('File is not an pdf.', file.type, file);
                return;
            }

            const reader = new FileReader();
            reader.addEventListener('load', (event) => {
                this.setState({ parrafo: event.target.result });
                console.log(event.target.result);
            });
            reader.readAsDataURL(file);
        }

    }

    datospdf(pdf) {
        this.setState({
            show: true,
            id: pdf.id,
            parrafo: pdf.parrafo
        });
    }



    render() {
        const { tips, parrafo, show, id, show2, titulo, show3, fecha } = this.state;
        let pdf = this.state.pdf;

        if (pdf == 1) {
            pdf = true;
        } else if (pdf == 0) {
            pdf = false;
        }


        const filarender = tips.map((tips) => {
            return (
                <tr>
                    <td>{tips.titulo}</td>
                    <td>{tips.id}</td>
                    <td>{tips.fecha}</td>
                    <td>
                        <img onClick={() => { this.setState({ show: true, id: tips.id }); }} style={{ cursor: "pointer", width: "20px" }} src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5f/Red_X.svg/1024px-Red_X.svg.png" />
                    </td>
                </tr>
            );
        });


        return (
            <div className="fondo_noticias_adm" >
                <Modal show={show} onHide={() => { this.setState({ show: false }); }} >
                    <Modal.Header closeButton>
                        <Modal.Title>Eliminar tip?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Estas a punto de eliminar una tip, Estas seguro?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { this.setState({ show: false }); }}>
                            Cancelar
                            </Button>
                        <Button variant="primary" onClick={() => api_adm("eliminar_tip", id)}>
                            Eliminar
                            </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={show3} onHide={() => { this.setState({ show3: false }); }} size="xl">

                    <div style={{ marginTop: "8px" , marginLeft: "20px"}}>
                        <Form.Check
                            style={{ marginTop: "8px" }}
                            checked={pdf}
                            onChange={() => { this.setState({ pdf: !pdf }); }}
                            type="switch"
                            id="custom-switch"
                            label="pdf"
                        />
                    </div>
                    <Modal.Header closeButton>
                        <Modal.Title>Nueva tip</Modal.Title>
                    </Modal.Header>
                    {pdf ?
                        <div>
                            <Modal.Body style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "fit-content" }}>
                                <div>
                                    <div style={{ marginTop: "8px" }}>
                                        <Form.Label>Titulo</Form.Label>
                                        <Form.Control value={titulo} onChange={this.handleChangetitulo} />
                                    </div>
                                    <div style={{ marginTop: "8px" }}>
                                        <Form.File style={{ marginTop: "8px" }} onChange={this.handlechangepdf} accept="application/pdf" />
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => { this.setState({ show3: false }); }}>
                                    Cancelar
                                </Button>
                                <Button variant="primary" onClick={() => api_adm("crear_tip", 0, titulo, parrafo, undefined, "pdf", fecha, undefined, undefined)}>
                                    Guardar
                                </Button>
                            </Modal.Footer>
                        </div>
                        :
                        <div>
                            <Modal.Body style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "fit-content" }}>
                                <div>
                                    <div style={{ marginTop: "8px" }}>
                                        <Form.Label>Titulo</Form.Label>
                                        <Form.Control value={titulo} onChange={this.handleChangetitulo} />
                                    </div>
                                    <div style={{ marginTop: "8px" }}>
                                        <Form.Label>Contenido</Form.Label>
                                        <Form.Control as="textarea" rows={2} value={parrafo} onChange={this.handleChangeparrafo} />
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => { this.setState({ show3: false }); }}>
                                    Cancelar
                            </Button>
                                <Button variant="primary" onClick={() => api_adm("crear_tip", 0, titulo, parrafo, undefined, "texto", fecha, undefined, undefined)}>
                                    Guardar
                        </Button>
                            </Modal.Footer>
                        </div>
                    }

                </Modal>
                <Button variant="primary" style={{ marginTop: "30px" }} onClick={() => {
                    var f = new Date();
                    const fecha = cfecha(f);
                    this.setState({
                        show3: true, id: 0,
                        titulo: "",
                        parrafo: "",
                        imagen: "",
                        checked: false,
                        fecha: fecha
                    });
                }}>Crear tip</Button>
                {Tabla_adm(filarender, "Titulo", <th>id</th>,<th>Fecha</th>, null)}
            </div>
        );
    }
}




export default Tips_adm;