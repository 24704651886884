import './App.css';
import "./App.scss";
import 'bootstrap/dist/css/bootstrap.min.css';
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Inicio from './componentes/inicio';
import Layout from './componentes/Layout';
import Galeria from './componentes/Galeria';
import Noticias from './componentes/Noticias';
import Perfil from './componentes/variablesSesion';
import Inicio_adm from './componentes/administracion/Inicio_adm';
import Galeria_adm from './componentes/administracion/Galeria_adm';
import Noticias_adm from './componentes/administracion/Noticias_adm';
import Carrusel_adm from './componentes/administracion/Carrusel_adm';
import Tips_adm from './componentes/administracion/tips_adm';
import Login from './componentes/Login';
import { Cargando } from './componentes/subcomponentes';
import Lutiles from "./componentes/Lutiles";
import Lutiles_adm from './componentes/administracion/Lutiles_adm';
import PDFs_adm from './componentes/administracion/PDFs_adm';
import Tips from './componentes/Tips';
import NotFound from './componentes/NotFound';
import Personal from './componentes/Personal';
import Personal_adm from './componentes/administracion/personal_adm';
import Mision_vision_sello from './componentes/Mision_vision_sello';
const axios = require('axios');



class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eventos: [],
      noticias: [],
      carrusel: [],
      tips: [],
      isLoaded: false
    };
  }
  componentDidMount() {
    axios.get("https://centroangelitos.cl/api/api_angelitos.php")
      .then((response) => response.data)
      .then((json) => {
        const eventos = json.datos.eventos.sort();
        eventos.reverse();
        const noticias = json.datos.noticias.sort();
        noticias.reverse();
        const carrusel = json.datos.carrusel.sort();
        const tips = json.datos.tips.sort();
        tips.reverse();
        const lutiles = json.datos.lutiles.sort();
        lutiles.reverse();
        const pdf = json.datos.pdf.sort();
        pdf.reverse();
        this.setState({ eventos: eventos });
        this.setState({ noticias: noticias });
        this.setState({ carrusel: carrusel });
        this.setState({ tips: tips });
        this.setState({ lutiles: lutiles });
        this.setState({ pdf: pdf });
        this.setState({ isLoaded: true });
        console.log(json);
      })
      .catch((error) => { console.log(error); });
  }

  render() {
    const { eventos, noticias, isLoaded, carrusel, tips, lutiles, pdf } = this.state;
    if (isLoaded == true) {
      return (
        <Router>
          <Route exact path="/">
            <Redirect to="/inicio" />
          </Route>
          <Route exact path="/angelitos">
            <Redirect to="/inicio" />
          </Route>
          <Layout pdf={pdf}>
            <Switch>
              <Route path="/inicio" render={(props) => (<Inicio noticias={noticias} carrusel={carrusel} eventos={eventos} />)} />
              <Route path="/Galeria" render={(props) => (<Galeria eventos={eventos} />)} />
              <Route path="/noticias" render={(props) => (<Noticias noticias={noticias} />)} />
              <Route path="/tips" render={(props) => (<Tips tips={tips} />)} />
              <Route path="/login" render={(props) => (<Login />)} />
              <Route path="/lutiles" render={(props) => (<Lutiles lutiles={lutiles} />)} />
              <Route path="/mision-vision-sello" render={(props) => (<Mision_vision_sello />)} />
              <Route path="/Colaboradores" render={(props) => (<Personal />)}  />
              <Route path="/personal">
                <Redirect to="/Colaboradores" />
              </Route>
              
              <PrivateRoute path="/administracion">
                <Inicio_adm noticias={noticias} />
              </PrivateRoute>
              <PrivateRoute path="/egaleria" >
                <Galeria_adm eventos={eventos} />
              </PrivateRoute>
              <PrivateRoute path="/enoticias" >
                <Noticias_adm noticias={noticias} />
              </PrivateRoute>
              <PrivateRoute path="/ecarrusel" >
                <Carrusel_adm carrusel={carrusel} />
              </PrivateRoute>
              <PrivateRoute path="/etips" >
                <Tips_adm tips={tips} />
              </PrivateRoute>
              <PrivateRoute path="/eutiles" >
                <Lutiles_adm lutiles={lutiles} />
              </PrivateRoute>
              <PrivateRoute path="/epdf" >
                <PDFs_adm pdf={pdf} />
              </PrivateRoute>
              <PrivateRoute path="/epersonal" >
                <Personal_adm />
              </PrivateRoute>
              <Route component={NotFound} />
            </Switch>
          </Layout>
        </Router>
      );
    } else {
      return (
        <Cargando />
      );
    }
  }
}


function PrivateRoute({ children, ...rest }) {
  const logeado = Perfil.logeado();
  if (logeado == "true") {
    return (
      <Route
        {...rest}
        render={({ location }) => (children)}
      />
    );
  } else {
    return (
      <Route {...rest} render={({ location }) => (<Redirect to={{ pathname: "/login", state: { from: location } }} />)} />
    );
  }

}

export default App;
