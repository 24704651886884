var Perfil = (function () {

    var logeado = function () {
        return window.sessionStorage.getItem('logeado');
    };

    var cambiarlogeado = function (estado) {
        window.sessionStorage.setItem('logeado', estado);;
        // Also set this in cookie/localStorage
    };


    return {
        logeado: logeado,
        cambiarlogeado: cambiarlogeado,
    };

})();

export default Perfil;