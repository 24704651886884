import React from "react";
import ImageGallery from 'react-image-gallery';
import {
    BrowserRouter as Router,
    Link,
    Redirect
} from "react-router-dom";
import { Table, Form, Modal, Button, Alert, Spinner } from "react-bootstrap";
import { Cargando, getQueryVariable } from "./subcomponentes";
import inicio_img from "../lutilesimg.webp";
const axios = require('axios');

export default class Lutiles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lutiles: props.lutiles,
            isLoaded: true,
            id: 0,
            link: ""
        };
    }
    componentDidMount() {
        /*
        ? hacer una sola llamada al api por el pdf del curso especifico?
        const id = this.state.id
        const data = {
            id: id,
        }
        axios({
            method: "post",
            url: "https://centroangelitos.cl/api/api_imagenes.php",
            data: JSON.stringify(data),
        }).then(json => {
            const evento = json.data.datos[0]
            this.setState({ evento: evento })
            this.setState({ isLoaded: true })
        })
            .catch((error) => { console.log(error) })
        */

    }
    datospdf(e) {

        const lutiles = this.state.lutiles;
        const iofid = [];
        const id = e.target.value;
        if (id != 0) {
            lutiles.map((pdf) => { iofid.push(pdf.id); });
            const iof = iofid.indexOf(id);
            this.setState({
                id: e.target.value,
                link: lutiles[iof].link
            });
        }

    }


    render() {
        const { isLoaded, id, lutiles, link } = this.state;
        const options = lutiles.map((lista) => {
            return (
                <option value={lista.id}>{lista.curso}</option>
            );
        });


        return (
            <div className="utiles_fondo">
                <div style={{ display: "flex", height: "fit-content", justifyContent: "center" }}>
                    <select style={{ width: "150px", margin: "50px" }} onChange={(e) => this.datospdf(e)}>
                        <option value="0" >Seleccionar curso</option>
                        {options}
                    </select>
                </div>

                <div style={{ display: "flex", flex: 1, backgroundColor: "white", flexDirection: "column", alignItems: "center", height: "100%" }}>
                    {id == 0 ?
                        <img src={inicio_img} style={{ width: "20%", minWidth: "220px" }} />
                        :
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", flex: 1 }}>
                            <a href={link} target="_blank">abrir en ventana</a>
                            <iframe className="pv_lutiles_pdf" src={link}></iframe>
                        </div>
                    }
                </div>

            </div>

        );



    }

}